import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppLoader from "components/AppLoader";
import { getConnectedAccountRefreshLink } from "api";

export default function StripeConnectAccountContainer() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const handleConnectAccount = async () => {
    try {
      setIsLoading(true);

      const response = await getConnectedAccountRefreshLink();

      if (response?.success) {
        window.location.href = response?.data?.url;
      }
    } catch (error: any) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const redirectToProjectDetails = async () => {
    const returnUrl = await localStorage.getItem("returnUrl");
    if (returnUrl) {
      navigate(returnUrl);
      localStorage.removeItem("returnUrl");
    } else navigate("/settings");
  };

  React.useEffect(() => {
    const type = searchParams.get("type");

    if (type === "refresh") {
      handleConnectAccount();
      return;
    }

    if (type === "return") {
      redirectToProjectDetails();
    }
  }, []);

  return <div>{isLoading && <AppLoader isLoading={isLoading} />}</div>;
}
