import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import { AccountType, RUNWAY_CALENDLY_URL } from "utils/constants";
// import { wrapNameTwoLetters } from "utils/common";
// import dashboardLogo from "../../assets/imgs/dashboard.svg";

// import Button from "../Button";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
// import { ContentPasteOffSharp } from "@mui/icons-material";
import Images from "assets/images";

const DashboardNav = {
  id: "1",
  icon: "fa-sharp fa-regular fa-grid-2",
  title: "Dashboard",
  path: "/dashboard",
};

const CreateProjectNav = {
  id: "2",
  icon: "fa-regular fa-square-plus",
  title: "Create Project",
  path: "/projects/create",
};

const ExploreProjectsNav = {
  id: "2",
  icon: "far fa-search",
  title: "Search Projects",
  path: "/projects?explore=true",
};

const ProjectsNav = {
  id: "3",
  icon: "far fa-file-alt",
  title: "My Projects",
  path: "/projects",
};

const BusinessProfileNav = {
  id: "4",
  icon: "fa-light fa-circle-user",
  title: "User Profile",
  path: "/settings",
};

const StudentProfileNav = {
  id: "4",
  icon: "fa-light fa-circle-user",
  title: "Profile",
  path: "/settings",
};

const AdminNav = {
  id: "5",
  icon: AdminPanelSettingsOutlinedIcon,
  title: "Admin",
  path: "/admin",
};

const MyTeam = {
  id: "6",
  icon: "fa-light fa-users",
  title: "My Team",
  path: "/",
};

const LogoutNav = {
  id: "7",
  icon: "fa-light fa-arrow-left-from-bracket",
  title: "Logout",
};

const ManagePayment = {
  id: "8",
  icon: "fal fa-file-alt",
  title: "Manage Payment",
  path: "/manage-payment",
};

const ChatNav = {
  id: "9",
  icon: "fa-sharp fa-light fa-message",
  title: "Messages",
  path: "/chat",
};

const mobileNavBar = [
  {
    id: "1",
    icon: "fa-sharp fa-regular fa-grid-2",
    title: "Home",
    path: "/dashboard",
  },
  {
    id: "2",
    icon: "fa-regular fa-square-plus",
    title: "Create",
    path: "/projects/create",
  },
  {
    id: "3",
    icon: "fa fa-file-lines",
    title: "Projects",
    path: "/projects",
  },
  {
    id: "4",
    icon: "fa-light fa-circle-user",
    title: "Profile",
    path: "/settings",
  },
];

type Props = {
  isLoggedIn: boolean;
  user: any;
  onLogout: () => void;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
};

const newNavBar = ({
  isLoggedIn,
  user,
  onLogout,
  manuBarExpend,
  manuBarToogle,
}: Props) => {
  // const navigate = useNavigate();
  let { pathname } = useLocation();
  const isSearchTab = window.location.search.includes("explore=true");
  pathname = pathname.includes("/projects/details") ? "/projects" : pathname;

  const [state, setState] = useMergeState({
    navs: [],
    otherNavs: [],
    mobileNavs: [],
    selectedNav: {},
    status: [],
    types: [],
    search: "",

    shouldShowFilterMenu: false,
    shouldExpandNav: false,
  });

  const handleLogout = () => {
    onLogout();
  };

  useEffect(() => {
    const mainNav = pathname.slice(1);
    const topNav = state.navs.find((elem: any) => elem.path === `/${mainNav}`);

    if (topNav?.path) {
      setState({ selectedNav: topNav });
    }
  }, [state.navs]);

  useEffect(() => {
    let navs: any = [];
    let otherNavs: any = [];
    let mobileNavs: any = [];

    if (!isLoggedIn) {
      ProjectsNav.path = "/projects?explore=true";
    }

    if (user?.accountType === AccountType.BUSINESS) {
      navs = [DashboardNav, CreateProjectNav, ProjectsNav, ChatNav];
      otherNavs = [BusinessProfileNav, LogoutNav];
      mobileNavs = mobileNavBar;
    }

    if (user?.accountType === AccountType.STUDENT) {
      navs = user?.isSuperAdmin
        ? [DashboardNav, ExploreProjectsNav, ProjectsNav]
        : [DashboardNav, ExploreProjectsNav, ProjectsNav, ChatNav];
      otherNavs = [StudentProfileNav, LogoutNav];
      mobileNavs = mobileNavBar.map((item: any) =>
        item.id === "2"
          ? {
              id: "2",
              icon: "far fa-search",
              title: "Search",
              path: "/projects?explore=true",
            }
          : item
      );
    }

    if (user?.isSuperAdmin) {
      // navs.push(ManagePayment);
      navs.push(...[ManagePayment, AdminNav]);
    }

    setState({ navs, otherNavs, mobileNavs });
  }, [user]);

  // const profileLatters = (
  //   <div className="profile_image">
  //     <p className="profileText">
  //       {wrapNameTwoLetters(user?.firstName, user?.lastName)}
  //     </p>
  //   </div>
  // );
  const isActive = (nav: any) => {
    let returnString = "";
    if (
      isSearchTab &&
      (nav.title === "Search Projects" || nav.title === "Search")
    ) {
      returnString = "active";
    } else {
      returnString =
        pathname.slice(1) === nav.path.slice(1) && isSearchTab === false
          ? "active"
          : "";
    }
    return returnString;
  };

  return (
    <>
      <div
        className={manuBarExpend ? "l_navbar show" : "l_navbar"}
        id="nav-bar"
      >
        <nav className="nav">
          <div className="w-100">
            <a href="/" className="nav_logo pb-5 w-100 nav_logo_full">
              {" "}
              <img src={Images.Logo_white} />{" "}
            </a>
            <a href="/" className="nav_logo pb-5 w-100 nav_logo_single">
              {" "}
              <img src={Images.Logo_single_white} />{" "}
            </a>
            <div className="nav_list">
              {state.navs.map((nav: any, index: number) => (
                <Link
                  to={nav.path}
                  className={`nav_link ${isActive(nav)} ${
                    nav.title === "Messages" ? "mesg_icon" : ""
                  }`}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <span className="nav_icon">
                    <i className={nav.icon} />
                    {/* {nav.title === "Messages" ? (
                      <span className="mesg_dot" />
                    ) : null} */}
                  </span>
                  <span className="nav_name">{nav.title}</span>
                </Link>
              ))}
            </div>
          </div>
          <div className="d-flex align-items-end w-100">
            <div className="nav_list w-100">
              {state.otherNavs.map((nav: any, index: number) =>
                nav.title === "Logout" ? (
                  <div
                    className="nav_link cursor_pointer"
                    key={index}
                    onClick={handleLogout}
                  >
                    <span className="nav_icon">
                      <i className={nav.icon} />
                    </span>
                    <span className="nav_name">{nav.title}</span>
                  </div>
                ) : (
                  <Link
                    to={nav.path}
                    className={`nav_link ${isActive(nav)}`}
                    style={{ textDecoration: "none" }}
                    key={index}
                  >
                    <span className="nav_icon">
                      <i className={nav.icon} />
                    </span>
                    <span className="nav_name">{nav.title}</span>
                  </Link>
                )
              )}
            </div>
          </div>
        </nav>
      </div>
      <ul className="mobile_navbar d-none">
        {state.mobileNavs.map((nav: any, index: number) => (
          <li key={index} className={`${isActive(nav)}`}>
            <Link to={nav.path}>
              <span>
                <i className={nav.icon} />
              </span>
              <p>{nav.title}</p>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default newNavBar;
