import React, { useEffect, useState, useRef } from "react";
import { StreamChat, ChannelSort } from "stream-chat";
import {
  Chat,
  Channel,
  ChannelList,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelStateContext,
} from "stream-chat-react";
import moment from "moment";
import Images from "assets/images";
import AppLoader from "components/AppLoader";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMergeState } from "utils/custom-hooks";
import { getMeetingLink, getProjectDetails, getUserToken } from "api";
import { wrapFullName, wrapNameTwoLetters } from "utils/common";
import { AccountType, StudentProjectStatus } from "utils/constants";
import "stream-chat-react/dist/css/v2/index.css";
import "./layout.css";

const apiKey: any = process.env.REACT_APP_STREAM_API_KEY;
let isSetChannel = true;

const CustomChannelPreview = (props: any) => {
  const {
    channel,
    activeChannel,
    setActiveChannel,
    isBusiness,
    handleActiveChannel,
  } = props;
  const latestMessageAt = channel.state.last_message_at;
  const isSelected = channel.id === activeChannel?.id;

  const { messages } = channel.state;
  const latestMessage = messages[messages.length - 1]?.text?.slice(0, 30);
  const data = channel?.data || null;
  const studentStatus = data
    ? data.studentStatus
      ? data.studentStatus
      : data.suggestedStudent
      ? "APPLIED"
      : null
    : null;

  useEffect(() => {
    if (isSetChannel) {
      handleActiveChannel(channel, isSetChannel);
      isSetChannel = false;
    }
  }, []);

  const handleClick = () => {
    setActiveChannel?.(channel);
    handleActiveChannel(channel, isSetChannel);
  };

  const profileLatters = (
    firstName: string | null,
    lastName: string | null
  ) => (
    <div
      className="profile_image"
      style={{
        backgroundColor: "black",
        width: 50,
        height: 50,
        borderRadius: 100,
      }}
    >
      <p className="profileText">
        {wrapNameTwoLetters(firstName ?? "", lastName ?? "")}
      </p>
    </div>
  );

  return (
    <button
      className={`channel-preview ${
        isSelected ? "channel-preview_selected" : "channel-preview_not_selected"
      }`}
      disabled={isSelected}
      onClick={handleClick}
    >
      <div className="active">
        <div className="d-flex">
          {data && isBusiness && data.studentLogo ? (
            <div className="avator_img">
              <img src={data.studentLogo} />
            </div>
          ) : data && !isBusiness && data.businessLogo ? (
            <div className="avator_img">
              <img src={data.businessLogo} />
            </div>
          ) : (
            <div className="candidate_profile">
              {profileLatters(
                data?.studentName.split(" ")[0] ?? "",
                data?.studentName.split(" ")[1] ?? ""
              )}
            </div>
          )}
          <div className="ps-3 w-100">
            <div className="d-flex justify-content-between">
              <p className="text_black fw-bold font_14 d-flex align-items-start">
                {data
                  ? isBusiness
                    ? data.studentName
                    : data.businessName
                  : ""}
                {studentStatus === StudentProjectStatus.APPLIED ? (
                  <span className="blue_tick ms-2">
                    <i className="fa-regular fa-check" />
                  </span>
                ) : studentStatus ===
                  StudentProjectStatus.REQUESTED_TO_INTERVIEW ? (
                  <span className="circle_icon bg_light_orange text_orange ms-2">
                    <i className="fal fa-clock" />
                  </span>
                ) : studentStatus === StudentProjectStatus.IN_PROGRESS ? (
                  <span className="circle_icon bg_light_green text_green ms-2">
                    <i className="fa-sharp fa-solid fa-arrow-rotate-left" />
                  </span>
                ) : null}
              </p>
              <p className="text_black font_12 ms-2">
                {latestMessageAt ? moment(latestMessageAt).format("h:mma") : ""}
              </p>
            </div>
            <p className="text_secondary lh-sm font_12">
              {data
                ? isBusiness
                  ? data.studentUniversity
                  : data.businessName
                : ""}
            </p>
            {latestMessage ? (
              <p className="text_black font_12">You: {latestMessage}</p>
            ) : null}
          </div>
        </div>
      </div>
    </button>
  );
};

const CustomChannelHeader = (props: {
  isBusiness: boolean;
  handleViewProject: () => void;
}) => {
  const { isBusiness, handleViewProject } = props;

  const { channel } = useChannelStateContext();
  const data: any = channel?.data || null;
  const studentStatus = data
    ? data.studentStatus
      ? data.studentStatus
      : data.suggestedStudent
      ? "APPLIED"
      : null
    : null;

  const profileLatters = (
    firstName: string | null,
    lastName: string | null
  ) => (
    <div className="chat_header_initials">
      <p className="profileText">
        {wrapNameTwoLetters(firstName ?? "", lastName ?? "")}
      </p>
    </div>
  );

  return (
    <div className="d-flex justify-content-between border-bottom border_gray pb-3 align-items-center">
      <div className="d-flex ps-3 pt-3">
        {data && isBusiness && data.studentLogo ? (
          <div className="main_img main_img_header">
            <img src={data.studentLogo} />
          </div>
        ) : data && !isBusiness && data.businessLogo ? (
          <div className="main_img main_img_header">
            <img src={data.businessLogo} />
          </div>
        ) : (
          <div className="main_img_header">
            {profileLatters(
              data?.studentName.split(" ")[0] ?? "",
              data?.studentName.split(" ")[1] ?? ""
            )}
          </div>
        )}
        <div className="ps-3">
          <p className="text_black fw-bold d-flex align-items-center">
            {data ? (isBusiness ? data.studentName : data.businessName) : ""}
            {studentStatus === StudentProjectStatus.APPLIED ? (
              <span className="blue_tick ms-2">
                <i className="fa-regular fa-check" />
              </span>
            ) : studentStatus ===
              StudentProjectStatus.REQUESTED_TO_INTERVIEW ? (
              <span className="circle_icon bg_light_orange text_orange ms-2">
                <i className="fal fa-clock" />
              </span>
            ) : studentStatus === StudentProjectStatus.IN_PROGRESS ? (
              <span className="circle_icon bg_light_green text_green ms-2">
                <i className="fa-sharp fa-solid fa-arrow-rotate-left" />
              </span>
            ) : null}
          </p>
          <p className="text_secondary font_14">
            {data
              ? isBusiness
                ? data.studentUniversity
                : data.businessName
              : ""}
          </p>
        </div>
      </div>
      <button
        type="button"
        className="btn btn_sm bg_secondary px-2 py-0 me-3 mt-3 font_14"
        onClick={handleViewProject}
      >
        View Project
      </button>
    </div>
  );
};

const BusinessChat = ({
  user,
  manuBarExpend,
  manuBarToogle,
}: {
  user: any;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
}) => {
  const userId = user._id;
  const defautlsFilters = {
    type: "messaging",
    users: [userId],
    members: { $in: [userId] },
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId") || "";
  const studentId = searchParams.get("studentId") || "";
  const [filters, setFilters] = useState<any>({
    ...defautlsFilters,
  });
  const [searchText, setSearchText] = useState<any>(null);
  const [channel, setChannel] = useState<any>(null);
  const [state, setState] = useMergeState({
    interviewLink: "",
    interviewDate: "",
    interviewTime: "",
    interviewType: 0,
    meetingId: "",
    externalInterviewLink: "",
    note: "",
    projectInfo: {},
    client: null,
  });
  const chatRoomInfo = useRef<any>({ studentId, projectId });
  const isBusinessAccount = user.accountType === AccountType.BUSINESS;
  const channelData = channel?.data || null;
  const disableDateSeparator = true;

  const sort: ChannelSort = { last_message_at: -1 };

  const generateUserToken = async (id: string) => {
    const res = await getUserToken({ userId: id });
    if (res.success) return res.userToken;
    return null;
  };

  useEffect(() => {
    const connectUser = async () => {
      try {
        const userToken = await generateUserToken(userId);
        const chatClient: any = StreamChat.getInstance(apiKey);
        await chatClient.connectUser(
          {
            id: userId,
            name: wrapFullName(user?.firstName, user?.lastName),
            image: user?.avatar,
          },
          userToken
        );
        // const userToken = await generateUserToken(userId);
        // const chatClient: any = StreamChat.getInstance(apiKey);
        // if (
        //   chatClient.tokenManager.token === userToken &&
        //   chatClient.userID === userId
        // )
        //   return;
        // await chatClient.connectUser(
        //   {
        //     id: userId,
        //     name: wrapFullName(user?.firstName, user?.lastName),
        //     image: user?.avatar,
        //   },
        //   userToken
        // );
        let interviewInfo = {};
        let projectResponse: any = {};
        if (projectId && studentId) {
          const promise = await Promise.all([
            getProjectDetails(projectId),
            getMeetingLink({ projectId, studentId }),
          ]);
          const response = promise[0];
          const meetingLinkResponse = promise[1];
          // await getProjectDetails(projectId);
          if (response?.success) {
            projectResponse = response.data;
            const studentInfo = response.data.studentProjects.find(
              (item: any) => item.student._id === studentId
            );
            if (studentInfo) {
              chatRoomInfo.current = {
                studentLogo:
                  response?.studentUrls?.studentAvatars[studentId] || null,
                studentName: wrapFullName(
                  studentInfo?.student?.firstName,
                  studentInfo?.student?.lastName
                ),
                studentUniversity: studentInfo.student.university,
                projectStatus: response?.data?.status,
                studentStatus: studentInfo.status,
                suggestedStudent: studentInfo.suggested,
                businessName: response?.data?.title || "",
                businessLogo: response?.companyLogoUrl,
                studentId,
                projectId,
              };
            }
          }
          if (
            meetingLinkResponse?.success &&
            meetingLinkResponse.data.length > 0
          ) {
            interviewInfo = {
              interviewLink: meetingLinkResponse.data[0].link,
              interviewDate: meetingLinkResponse.data[0].date,
              interviewTime: meetingLinkResponse.data[0].time,
              meetingId: meetingLinkResponse.data[0]._id,
              note:
                meetingLinkResponse.data[0]?.note ||
                meetingLinkResponse.data[0].note,
              interviewType:
                meetingLinkResponse.data[0].interviewType === "external"
                  ? 1
                  : 0,
            };
          }
          const lastChars = studentId.substring(studentId.length - 6);
          const channel: any = chatClient.channel(
            "messaging",
            `${user.firstName}-chat-${
              chatRoomInfo.current?.studentName
                .replace(/[0-9]/g, "")
                .replace(/ /g, "") || ""
            }-${lastChars}`,
            {
              members: [userId, studentId],
              users: [userId, studentId],
              ...chatRoomInfo.current,
            }
          );
          await channel.watch();
        }

        setState({
          ...interviewInfo,
          projectInfo: projectResponse,
          client: chatClient,
        });

        setChannel(channel);
      } catch (error) {
        console.error("Error connecting user or creating channel:", error);
      }
    };
    connectUser();
    return () => {
      state.client?.disconnectUser();
      isSetChannel = true;
    };
  }, []);

  const handleViewProject = () => {
    navigate(`/projects/details?id=${channelData?.projectId}`);
  };

  const handleViewProfile = () => {
    navigate(`/projects/details?id=${channelData?.projectId}`, {
      state: {
        isShowProfile: true,
        chatStudentId: channelData.studentId,
      },
    });
  };

  const handleActiveChannel = async (channel: any, flag: boolean) => {
    setChannel(channel);
    if (
      channel?.data?.projectId &&
      channel?.data?.studentStatus ===
        StudentProjectStatus.REQUESTED_TO_INTERVIEW
    ) {
      const project_id = channel?.data?.projectId || "";
      const student_id = channel?.data?.studentId || "";
      const promise = await Promise.all([
        getProjectDetails(project_id),
        getMeetingLink({ projectId: project_id, studentId: student_id }),
      ]);
      const response = promise[0];
      const meetingLinkResponse = promise[1];
      let interviewInfo = {};
      if (meetingLinkResponse?.success) {
        interviewInfo = {
          interviewLink: meetingLinkResponse.data[0].link,
          interviewDate: meetingLinkResponse.data[0].date,
          interviewTime: meetingLinkResponse.data[0].time,
          meetingId: meetingLinkResponse.data[0]._id,
          note:
            meetingLinkResponse.data[0]?.note ||
            meetingLinkResponse.data[0].note,
          interviewType:
            meetingLinkResponse.data[0].interviewType === "external" ? 1 : 0,
        };
      }
      setState({
        ...interviewInfo,
        projectInfo: response?.data || {},
      });
    }
  };

  const handleSearch = async () => {
    if (!searchText) {
      setFilters({
        ...defautlsFilters,
      });
      return;
    }
    const chatClient: any = StreamChat.getInstance(apiKey);

    try {
      const channels = await chatClient.queryChannels({
        type: "messaging", // Channel type, replace if needed
        studentName: { $exists: true },
      });
      const filteredChannels = channels.filter((channel: any) =>
        channel.data.studentName
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      console.log(filteredChannels);

      if (filteredChannels.length > 0) {
        setFilters({
          ...defautlsFilters,
          id: { $in: filteredChannels.map((channel: any) => channel.id) },
        });
      } else {
        setFilters({ id: { $in: ["no-matches"] } }); // Reset or use a filter that shows no results if no matches
      }
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      await handleSearch();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText]);

  const isLoading = true;
  if (!state.client) return <AppLoader isLoading={isLoading} />;

  return (
    <>
      <header
        className={`header ${manuBarExpend ? "body_pd" : ""}`}
        id="header"
      >
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />{" "}
        </div>
        <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center w-100">
          <span className="d-block d-md-none me-3">
            <img src={Images.Logo} />
          </span>
          <div className="search_bar position-relative">
            <input
              type="search"
              name=""
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <i className="far fa-search" />
          </div>
        </div>
      </header>
      <div className="px-0 w-100 pb-5 pb-md-0 overflow-hidden chat_window_height">
        <div className="bg_white">
          <div className="frame">
            <Chat client={state?.client} theme="messaging light">
              <div className="sidepanel">
                <ChannelList
                  Preview={(ChannelPreviewProps) => (
                    <CustomChannelPreview
                      {...ChannelPreviewProps}
                      isBusiness={isBusinessAccount}
                      handleActiveChannel={handleActiveChannel}
                    />
                  )}
                  // Preview={CustomChannelPreview}
                  filters={filters}
                  sort={sort}
                />
              </div>
              <div className="content">
                <Channel>
                  <Window>
                    <CustomChannelHeader
                      isBusiness={isBusinessAccount}
                      handleViewProject={handleViewProject}
                    />
                    <div className="message-list-wrapper">
                      {/* <VirtualizedMessageList /> */}
                      <MessageList
                        disableDateSeparator={disableDateSeparator}
                        hideNewMessageSeparator={disableDateSeparator}
                        // Message={CustomMessage}
                      />
                    </div>

                    <MessageInput />
                  </Window>
                  <Thread />
                </Channel>
              </div>
            </Chat>
            <div className="right_sidebar">
              <div className="main_img d-flex justify-center">
                <img
                  src={
                    channelData
                      ? isBusinessAccount
                        ? channelData.studentLogo
                        : channelData.businessLogo
                      : ""
                  }
                />
              </div>
              <p className="fw_500 text_black mt-3">
                {channelData
                  ? isBusinessAccount
                    ? channelData.studentName
                    : channelData.businessName
                  : ""}
              </p>
              <p className="text_rgba_50 font_12">
                {channelData
                  ? isBusinessAccount
                    ? channelData.studentUniversity
                    : channelData.businessName
                  : ""}
              </p>
              {channelData?.suggestedStudent ||
              channelData?.studentStatus === StudentProjectStatus.APPLIED ? (
                <p className="font_12 fw_500 text_secondary bg_light_secondary px-2 py-2 border_r_8px mx-auto mt-2 text-uppercase d-inline-block mb-3">
                  <span className="me-2">
                    <i className="fa-duotone fa-check" />
                  </span>
                  {channelData.suggestedStudent ? "Suggested" : "Applied"}{" "}
                </p>
              ) : channelData?.studentStatus ===
                StudentProjectStatus.IN_PROGRESS ? (
                <p className="font_12 fw_500 text_green bg_light_green px-2 py-2 border_r_8px mx-auto mt-2 text-uppercase d-inline-block mb-3">
                  <span className="text_green me-2">
                    <i className="fa-sharp fa-solid fa-arrow-rotate-left" />
                  </span>
                  Ongoing{" "}
                </p>
              ) : channelData?.studentStatus ===
                StudentProjectStatus.REQUESTED_TO_INTERVIEW ? (
                <p className="font_12 fw_500 text_orange bg_lightorange px-2 py-2 border_r_8px mx-auto mt-2 text-uppercase d-inline-block mb-3">
                  <span className="me-2">
                    <i className="fal fa-clock" />
                  </span>
                  Requested to Interview{" "}
                </p>
              ) : null}
              <div className="mid_box">
                {isBusinessAccount ? (
                  <button
                    type="button"
                    className="btn btn_small text_black border w-100 border_r_8px mb-2 fw-bold box_shadow"
                    onClick={handleViewProfile}
                  >
                    View Profile
                  </button>
                ) : null}
                {channelData?.studentStatus ===
                StudentProjectStatus.IN_PROGRESS ? (
                  <>
                    <button
                      type="button"
                      className="btn btn_small text_black border w-100 border_r_8px mb-2 fw-bold box_shadow"
                      onClick={handleViewProject}
                    >
                      {isBusinessAccount ? "Pay Invoice" : "Submit Timesheet"}
                    </button>
                    {isBusinessAccount ? (
                      <button
                        type="button"
                        className="btn btn_small text_black border w-100 border_r_8px mb-2 fw-bold box_shadow"
                        onClick={handleViewProject}
                      >
                        Edit Hourly Rate
                      </button>
                    ) : null}
                  </>
                ) : null}
                {channelData?.suggestedStudent ||
                channelData?.studentStatus === StudentProjectStatus.APPLIED ? (
                  <button
                    type="button"
                    className="btn btn_small text_black border w-100 border_r_8px mb-2 fw-bold box_shadow"
                    onClick={handleViewProfile}
                  >
                    Request to Interview
                  </button>
                ) : channelData?.studentStatus ===
                    StudentProjectStatus.REQUESTED_TO_INTERVIEW &&
                  state.interviewLink.length > 0 ? (
                  <div className="border border_gray border_r_8px p-3 mt-3 box_shadow">
                    <p className="fw-bold text_black font_12">
                      Interview Scheduled
                    </p>
                    <p className="fw-bold text_black mb-3">
                      {" "}
                      {`${moment(state.interviewDate).format(
                        "dddd, MMM Do"
                      )}`}{" "}
                    </p>
                    <button
                      type="button"
                      className="btn btn_small bg_secondary px-5"
                      onClick={handleViewProfile}
                    >
                      View Interview
                    </button>
                  </div>
                ) : null}
                {isBusinessAccount &&
                channelData?.studentStatus ===
                  StudentProjectStatus.REQUESTED_TO_INTERVIEW ? (
                  <div className="border border_gray border_r_8px p-3 mt-3 box_shadow">
                    <p className="fw-bold text_black mb-3"> Ready to Hire? </p>
                    <button
                      type="button"
                      className="btn btn_small bg_secondary px-5"
                      onClick={handleViewProject}
                    >
                      Start Project
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessChat;
