import React, { useEffect, useRef } from "react";
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getProjectDetails,
  listInvoices,
  updateProject,
  updateOnlineOfflineStatus,
  getStudentDetails,
} from "api";
import { useMergeState } from "utils/custom-hooks";
import AppliedProject from "components/MyProjects/AppliedProject";
import RequestInterviewProject from "components/MyProjects/RequestInterviewProject";
import OngoingProject from "components/MyProjects/OngoingProject";
import ListedProject from "components/MyProjects/ListedProject";
import {
  AccountType,
  BudgetType,
  ProjectStatus,
  StudentProjectStatus,
} from "utils/constants";
import {
  getStudentHourlyRate,
  shouldShowPremiumFeatures,
  wrapFullName,
} from "utils/common";
import CreateProjectsContainer from "containers/projects/create";

const getProjectFees = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "Salary TBD";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.studentRetainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return `${project.studentHourlyRate}`;
  }
};

const getBusinessProjectFees = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "Salary TBD";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.retainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return project.hourlyRate;
  }
};

type Props = {
  user: any;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
};

const ProjectDetails = ({ user, manuBarExpend, manuBarToogle }: Props) => {
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const projectType = searchParams.get("type");
  const projectId = searchParams.get("id") || "";
  const location = useLocation();
  const isShowProfile = location.state && location.state?.isShowProfile;
  const chatStudentId = location.state && location.state?.chatStudentId;

  const [state, setState] = useMergeState({
    description: "",
    responsibilties: [],
    qualifications: [],
    skills: [],
    timeSlots: {},
    managerFirstName: "",
    managerLastName: "",
    studentHourlyRate: "",
    hoursPerWeek: "",
    jobDocumentUrl: "",
    title: "",
    type: "",
    status: "",
    studentList: [],
    projectType: -1,
    duration: 0,
    totalStudents: -1,
    lastUpdate: "",
    selectedUserName: "",
    companyLogo: null,
    project: {},
    isEditProject: false,
    studentId: { email: "" },
  });

  const studentAvatars = useRef<any>({});
  const shouldProjectEdit = useRef(false);
  const isStudentDetailsAPICalled = useRef(false);

  const isBusinessAccount = user.accountType === AccountType.BUSINESS;

  const updateProjectType = (response: any) => {
    if (isBusinessAccount) {
      if (
        response.data.status === ProjectStatus.IN_PROGRESS ||
        response.data.status === ProjectStatus.ARCHIVED ||
        response.data.status === "ON_GOING"
      ) {
        setState({ projectType: 0 });
      }
      if (
        response.data.status === ProjectStatus.IN_ACTIVE ||
        response.data.status === "ACTIVE"
      ) {
        setState({ projectType: 3 });
      }
    } else {
      let studentSelect = response.data.studentProjects.filter(
        (data: any) => data.student && data.student._id === user._id
      );
      if (studentSelect.length === 0) {
        studentSelect = response.data.studentProjectsRemoved.filter(
          (data: any) => data.student && data.student._id === user._id
        );
        if (studentSelect.length > 0) {
          studentSelect = [
            {
              ...studentSelect[0],
              status: "IN_PROGRESS",
            },
          ];
          response.data = {
            ...response.data,
            status: "ARCHIVED",
            studentProjects: [
              ...response.data.studentProjects,
              ...studentSelect,
            ],
          };
        }
      }

      if (studentSelect.length === 0) return;

      if (studentSelect[0].status === StudentProjectStatus.APPLIED) {
        setState({ projectType: 2 });
      }
      if (
        studentSelect[0].status === StudentProjectStatus.REQUESTED_TO_INTERVIEW
      ) {
        setState({ projectType: 1 });
      }
      if (
        studentSelect[0].status === StudentProjectStatus.IN_PROGRESS ||
        studentSelect[0].status === ProjectStatus.ARCHIVED
      ) {
        setState({ projectType: 0 });
      }
    }
  };

  const handleChangeProjectStatus = async (status: string) => {
    try {
      const response = await updateProject({ id: projectId, status });

      if (response?.success) {
        setState({
          status: response.data.status,
        });
        updateProjectType(response);
        enqueueSnackbar("Project status updated successfully.", {
          variant: "success",
        });
      }
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleChangeCapHour = (value: boolean) => {
    setState({
      project: {
        ...state.project,
        isCapRate: value,
      },
    });
  };

  const handleChangeHourlyRate = (
    event: Event,
    newValue: number | number[]
  ) => {
    setState({
      project: {
        ...state.project,
        hourlyRate: newValue,
      },
    });
  };
  const handleChangeHoursPerWeek = (
    event: Event,
    newValue: number | number[]
  ) => {
    setState({
      project: {
        ...state.project,
        hoursPerWeek: newValue,
      },
    });
  };

  const handleUpdateState = (response: any) => {
    setState({
      project: response?.data,
      companyLogo: response?.companyLogoUrl || state.companyLogo,
      description: response.data.description,
      responsibilties: [response.data.keyResponsibilities],
      qualifications: [response.data.qualifications],
      skills: [...response.data.skills],
      timeSlots: response.data.interviewTimes,
      managerFirstName: response.data.owner.firstName || "",
      managerLastName: response.data.owner.lastName || "",
      studentHourlyRate: isBusinessAccount
        ? getBusinessProjectFees(response.data)
        : getProjectFees(response.data),
      hoursPerWeek: response.data.hoursPerWeek,
      jobDocumentUrl:
        response.data.docs.length > 0 ? response.data.docs[0].url : "",
      status: response.data.status,
      title: response.data.title,
      type: response.data.type,
      duration: response?.data?.duration,
      studentList: [...response.data.studentProjects],
      totalStudents: response.data.studentProjects.length,
      lastUpdate: response.data.createdAt,
      isEditProject: false,
    });
  };

  const handleEditProject = async () => {
    const payload = state.project;
    payload.id = projectId;
    payload.studentHourlyRate = +Number(
      getStudentHourlyRate(
        state.project.hourlyRate,
        shouldShowPremiumFeatures(user),
        state.project?.promo
      )
    );
    try {
      const response = await updateProject(payload);
      if (response && response.success) {
        handleUpdateState(response);
        // const project = {
        //   ...response?.data,
        //   studentProjects: state.project.studentProjects,
        // };
        // setState({
        //   project,
        //   studentHourlyRate: isBusinessAccount
        //     ? getBusinessProjectFees(project)
        //     : getProjectFees(project),
        // });
        enqueueSnackbar(response?.message, { variant: "success" });
      }
      return response;
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleOnlineOfflineStatus = async (status: boolean) => {
    try {
      const response = await updateOnlineOfflineStatus({
        id: projectId,
        status,
      });

      if (response?.success) {
        handleUpdateState(response);
        enqueueSnackbar("Project status updated successfully.", {
          variant: "success",
        });
      }
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleToggleEditProjectListing = (data: any) => {
    if (state.isEditProject && data) {
      handleUpdateState(data);
    } else setState({ isEditProject: false });
  };

  const handleGetStudentDetails = async () => {
    const response = await getStudentDetails({ id: chatStudentId });
    if (response.success) {
      const { data } = response;
      setState({
        selectedUserName: wrapFullName(data?.firstName, data?.lastName),
        studentId: data,
      });
    }
  };

  const init = async () => {
    if (!projectId) return;
    try {
      const response = await getProjectDetails(projectId);
      console.log("response -----", response);
      if (response?.success) {
        studentAvatars.current = response.studentUrls.studentAvatars;
        // response.data = {
        //   ...response.data,
        //   studentProjects: response.data.studentProjects.map((item: any) => ({
        //     ...item,
        //     student: {
        //       ...item.student,
        //       avatar:
        //         response?.studentUrls?.studentAvatars[item.student._id] || null,
        //     },
        //   })),
        // };
        updateProjectType(response);
        handleUpdateState(response);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (isShowProfile && !isStudentDetailsAPICalled.current) {
  //     handleGetStudentDetails();
  //     isStudentDetailsAPICalled.current = true;
  //   }
  // }, [isShowProfile]);

  useEffect(() => {
    if (isShowProfile && !isStudentDetailsAPICalled.current) {
      handleGetStudentDetails();
      isStudentDetailsAPICalled.current = true;
    }
    init();
  }, []);

  const isSelectedUserName = state?.selectedUserName?.length;

  return !state.isEditProject ? (
    <div>
      <div className={`header ${manuBarExpend ? "body_pd" : ""}`} id="header">
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />{" "}
        </div>
        <div className="fw-bold fs-6 ms-3 mb-0 d-flex align-items-center align-middle w-100 pt-3">
          {/* <span className="d-block d-md-none me-3">
            <img src="imgs/logo.png" />
          </span> */}
          {/* <span>My Projects</span> */}
          {user.accountType === "STUDENT" ? (
            <nav>
              <ol className="breadcrumb">
                <li className="me-2 cursor_pointer">
                  <span
                    className="me-2 text_secondary"
                    onClick={() => navigate("/projects")}
                  >
                    <i className="fa-regular fa-arrow-left" />
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/projects">
                    {/* <span className="me-2">
                      <i className="fa-regular fa-arrow-left" />
                    </span>{" "} */}
                    My Projects
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text_secondary"
                  aria-current="page"
                >
                  {state.title}
                </li>
              </ol>
            </nav>
          ) : (
            <span style={{ paddingBottom: 10 }}>My Projects</span>
          )}
        </div>
      </div>
      <div className="bg_light p-3 p-md-4 w-100 overflow-hidden mb-4">
        {user.accountType !== "STUDENT" && (
          <nav>
            <ol className="breadcrumb">
              <li className="me-2 cursor_pointer">
                <span
                  className="me-2 text_secondary"
                  onClick={() =>
                    isSelectedUserName
                      ? setState({
                          selectedUserName: "",
                          studentId: { email: "" },
                        })
                      : navigate("/projects")
                  }
                >
                  <i className="fa-regular fa-arrow-left" />
                </span>
              </li>
              <li className="breadcrumb-item">
                <Link to="/projects">
                  {/* <span className="me-2">
                    <i className="fa-regular fa-arrow-left" />
                  </span>{" "} */}
                  My Projects
                </Link>
              </li>
              <li
                className={`breadcrumb-item ${
                  !isSelectedUserName ? "text_secondary" : ""
                }`}
                aria-current="page"
              >
                {isSelectedUserName ? (
                  <a href={`/projects/details?id=${projectId}`}>
                    {state.title}
                  </a>
                ) : (
                  state.title
                )}
              </li>
              {isSelectedUserName ? (
                <li
                  className="breadcrumb-item text_secondary"
                  aria-current="page"
                >
                  {state?.selectedUserName}
                </li>
              ) : null}
            </ol>
          </nav>
        )}

        {state?.projectType === 0 && (
          <OngoingProject
            user={user}
            isShowProfile={isShowProfile}
            firstName={state.managerFirstName}
            lastName={state.managerLastName}
            companyLogo={state.companyLogo}
            studentInfo={state.studentId}
            projectId={projectId}
            hourlyRate={state.studentHourlyRate}
            hoursPerWeek={state.hoursPerWeek}
            title={state.title}
            type={state.type}
            duration={state.duration}
            jobDocumentUrl={state.jobDocumentUrl}
            project={state.project}
            business={state.project.business}
            studentAvatars={studentAvatars.current}
            interviewTimes={state?.timeSlots}
            handleUpdateProject={(project: any) =>
              handleUpdateState({ data: project })
            }
            handleToggleEditButton={(isEdit: any) => {
              shouldProjectEdit.current = isEdit;
              setState({ isEditProject: true });
            }}
            updateSelectedUserName={(name: string, id: any) => {
              setState({ selectedUserName: name, studentId: id });
            }}
            handleChangeProjectStatus={handleOnlineOfflineStatus}
            handleChangeCapHour={handleChangeCapHour}
            handleChangeHoursPerWeek={handleChangeHoursPerWeek}
            handleChangeHourlyRate={handleChangeHourlyRate}
            handleEditProject={handleEditProject}
          />
        )}
        {state.projectType === 1 && (
          <RequestInterviewProject
            companyLogo={state.companyLogo}
            description={state?.description}
            responsibilties={state?.responsibilties}
            qualifications={state?.qualifications}
            skills={state?.skills}
            timeSlots={state?.timeSlots}
            projectId={projectId}
            studentId={user._id}
            hourlyRate={state.studentHourlyRate}
            jobDocumentUrl={state.jobDocumentUrl}
            title={state.title}
            type={state.type}
            duration={state.duration}
            business={state.project.business}
            totalApplicants={state.totalStudents}
            lastUpdate={state.lastUpdate}
          />
        )}
        {state.projectType === 2 && (
          <AppliedProject
            companyLogo={state.companyLogo}
            description={state?.description}
            responsibilties={state?.responsibilties}
            qualifications={state?.qualifications}
            skills={state?.skills}
            projectId={projectId}
            hourlyRate={state.studentHourlyRate}
            jobDocumentUrl={state.jobDocumentUrl}
            title={state.title}
            type={state.type}
            business={state.project.business}
            duration={state.duration}
            lastUpdate={state.lastUpdate}
          />
        )}
        {state.projectType === 3 && (
          <ListedProject
            user={user}
            isShowProfile={isShowProfile}
            studentId={state.studentId}
            companyLogo={state.companyLogo}
            projectId={projectId}
            status={state.status}
            title={state.title}
            type={state.type}
            description={state?.description}
            responsibilties={state?.responsibilties}
            qualifications={state?.qualifications}
            skills={state?.skills}
            studentList={state?.studentList}
            interviewTimes={state?.timeSlots}
            duration={state.duration}
            hourlyRate={state.studentHourlyRate}
            jobDocumentUrl={state.jobDocumentUrl}
            project={state.project}
            business={state.project.business}
            updateSelectedUserName={(name: string, data: any) => {
              setState({ selectedUserName: name, studentId: data });
            }}
            handleToggleEditButton={(isEdit: any) => {
              shouldProjectEdit.current = isEdit;
              setState({ isEditProject: true });
            }}
            handleChangeProjectStatus={handleChangeProjectStatus}
            handleChangeCapHour={handleChangeCapHour}
            handleChangeHoursPerWeek={handleChangeHoursPerWeek}
            handleChangeHourlyRate={handleChangeHourlyRate}
            handleEditProject={handleEditProject}
          />
        )}
      </div>
    </div>
  ) : (
    <CreateProjectsContainer
      user={user}
      manuBarExpend={manuBarExpend}
      isEditProject={shouldProjectEdit.current}
      projectDetails={state.project}
      manuBarToogle={manuBarToogle}
      handleBackNavigation={(data: any) => handleToggleEditProjectListing(data)}
    />
  );
};

export default ProjectDetails;
